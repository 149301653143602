import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import "./OffersPageComponent.css";

function OffersPageComponent() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        console.log('User not logged in or token not available');
      }
    });
    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = async () => {
    if (!query) return; // Prevent empty queries
    setLoading(true);
    setError(null);

    let data = JSON.stringify({ query: query });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://us-central1-focusgrounds-auth.cloudfunctions.net/callablefunction',
      headers: { 
        'Content-Type': 'application/json'
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      setResults(response.data);
    } catch (err) {
      console.error('Error calling queryCallable function:', err); // More detailed error logging
      setError('Failed to fetch results: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const renderTable = () => {
    if (!results || results.length === 0) return null;

    return (
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Input</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result) => (
            <tr key={result.id}>
              <td>{result.id}</td>
              <td>{result.input}</td>
              <td>{result.Type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <h2>Limited Time Offers Page</h2>
      <input
        type="text"
        placeholder="Enter your query"
        value={query}
        onChange={handleInputChange}
      />
      <button onClick={handleSearch} disabled={loading}>
        Search
      </button>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {results && renderTable()}
    </div>
  );
}

export default OffersPageComponent;
