import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import React, {useState } from 'react';
import logo from './logo.svg';
import { Link } from 'react-router-dom';
import { auth } from './firebase';
import './HomePage.css';

const HomePageComponent = () => {
  const [user, setUser] = useState(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        setUser(currentUser);
        const redirectPath = location.state?.from || '/';
        if (location.pathname !== redirectPath) {
          history.push(redirectPath);
        }
      } else {
        if (location.pathname !== '/login') {
          history.push("/login", { from: location.pathname });
        }
      }
    });

    return () => unsubscribe();
  }, [history, location.pathname, location.state?.from]); // Include 'location.state?.from' in dependencies

  if (!user) {
    return null;
  }

  return (
    <div>
      <img src={logo} className="App-logo" alt="logo" />
      <div className="buttonContainer">
        <Link to="/chat">
          <button className="elegantButton">AI Powered Chat</button>
        </Link>
      </div>
      <div className="buttonContainer">
        <Link to="/offers">
          <button className="elegantButton">Exclusive Offers %</button>
        </Link>
        <Link to="/demandsupply">
          <button className="elegantButton">Demand & Supply</button>
        </Link>
      </div>
      <p>Welcome to Focus Grounds!</p>
      <h2>Your Personal Real Estate Ai</h2>
    </div>
  );
};

export default HomePageComponent;
