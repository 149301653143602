// Import React and useState from React library
import React, { useState } from 'react';

// Import necessary components and utilities from recharts
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Import CSS module for styling
import styles from './DemandAndSupplyComponent.css'; // Ensure you create this CSS module file

function DemandAndSupplyComponent() {
  const [data, setData] = useState([]);
  const [inputType, setInputType] = useState('demand'); // 'demand' or 'supply'
  const [bhkType, setBhkType] = useState('1 BHK'); // default BHK type

  const handleSubmit = (event) => {
    event.preventDefault();
    const existingDataIndex = data.findIndex(item => item.name === bhkType);

    if (existingDataIndex >= 0) {
      const newData = [...data];
      newData[existingDataIndex][inputType] = (newData[existingDataIndex][inputType] || 0) + 1;
      setData(newData);
    } else {
      setData([...data, { name: bhkType, [inputType]: 1, [inputType === 'demand' ? 'supply' : 'demand']: 0 }]);
    }
  };

  return (
    <div className={styles.container}>
      <h2>Demand and Supply</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <label className={styles.label}>
          Type:
          <select value={inputType} onChange={(e) => setInputType(e.target.value)} className={styles.select}>
            <option value="demand">Demand</option>
            <option value="supply">Supply</option>
          </select>
        </label>
        <label className={styles.label}>
          BHK Type:
          <select value={bhkType} onChange={(e) => setBhkType(e.target.value)} className={styles.select}>
            <option value="1 BHK">1 BHK</option>
            <option value="2 BHK">2 BHK</option>
            <option value="3 BHK">3 BHK</option>
            <option value="4 BHK">4 BHK</option>
          </select>
        </label>
        <button type="submit" className={styles.button}>Add</button>
      </form>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend />
          <Bar dataKey="demand" fill="#8884d8" />
          <Bar dataKey="supply" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default DemandAndSupplyComponent;
