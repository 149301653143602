import React, { useState } from 'react';
import './ContactUsComponent.css';

function ContactUsComponent() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Process form data here, such as sending to an API or email service
    console.log('Form data:', formData);
    alert('Thank you for your enquiry!');
    setFormData({ name: '', email: '', message: '' }); // Reset form after submission
  };

  return (
    <div className="contact-us-container">
      <h2>Contact Us</h2>
      <p>If you have any questions, please don't hesitate to contact us at <a href="mailto:info@example.com">info@example.com</a></p>
      
      <form onSubmit={handleSubmit} className="enquiry-form">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>

        <button type="submit" className="submit-button">Send Enquiry</button>
      </form>
    </div>
  );
}

export default ContactUsComponent;
