import React, { useState } from 'react';
import './DemoPage.css';

const DemoPageComponent = () => {
    const initialViewBox = "0 0 500 500";
    const [viewBox, setViewBox] = useState(initialViewBox);
    const [imageTransform, setImageTransform] = useState('');

    const imageUrl = `https://drive.google.com/uc?export=view&id=1AN0zTwXtDizguTaDl-LlUPYVQtz-ZvU_`;

    const polygons = [
        { id: 'polygon1', points: '50,50 150,50 150,150 50,150', label: 'Polygon 1' },
        { id: 'polygon2', points: '30,30 100,30 100,100 30,100', label: 'Polygon 2' },
        // Add more polygons as needed
    ];

    const calculateViewBox = (points) => {
        const coords = points.split(' ').map(point => point.split(',').map(Number));
        const xs = coords.map(coord => coord[0]);
        const ys = coords.map(coord => coord[1]);
        const minX = Math.min(...xs);
        const minY = Math.min(...ys);
        const width = Math.max(...xs) - minX;
        const height = Math.max(...ys) - minY;
        return `${minX} ${minY} ${width} ${height}`;
    };

    const calculateTransform = (points) => {
        // Example calculation, adjust as needed
        const coords = points.split(' ').map(point => point.split(',').map(Number));
        const xs = coords.map(coord => coord[0]);
        const ys = coords.map(coord => coord[1]);
        const minX = Math.min(...xs);
        const minY = Math.min(...ys);


        const scale = 2; // Example scale factor
        const translateX = -minX * scale;
        const translateY = -minY * scale;

        return `scale(${scale}) translate(${translateX}px, ${translateY}px)`;
    };

    const goToPolygon = (polygonId) => {
        const polygon = polygons.find(p => p.id === polygonId);
        if (polygon) {
            const newViewBox = calculateViewBox(polygon.points);
            const newTransform = calculateTransform(polygon.points);
            setViewBox(newViewBox);
            setImageTransform(newTransform);
        }
    };

    const resetView = () => {
        setViewBox(initialViewBox);
        setImageTransform('');
    };

    return (
        <div className="image-container">
            <img src={imageUrl} alt="Interactive" style={{ transform: imageTransform }} />
            <svg style={{ transform: imageTransform }} viewBox={viewBox}>
                {polygons.map(polygon => (
                    <polygon
                        key={polygon.id}
                        points={polygon.points}
                        fill="rgba(255, 0, 0, 0.5)"
                        stroke="black"
                        strokeWidth="2"
                    />
                ))}
            </svg>
            <div className="polygon-navigation">
                {polygons.map(polygon => (
                    <button key={polygon.id} onClick={() => goToPolygon(polygon.id)}>
                        Go to {polygon.label}
                    </button>
                ))}
                <button onClick={resetView}>Reset View</button>
            </div>
        </div>
    );
};

export default DemoPageComponent;
