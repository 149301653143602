import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';
import HomePageComponent from './HomePageComponent';
import UploadListingsComponent from './UploadListingsComponent'; 
import ContactUsComponent from './ContactUsComponent'; 
import DemandAndSupplyComponent from './DemandAndSupplyComponent'; 
import ChatComponent from './ChatComponent'; 
import OffersPageComponent from './OffersPageComponent'; 
import DemoPageComponent from './DemoPageComponent'; 
import Login from "./login";
import SignUp from "./register";
import profile from "./profile";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Switch>
            <Route path="/" exact component={HomePageComponent} />
            <Route path="/home" exact component={HomePageComponent} />
            <Route path="/upload" component={UploadListingsComponent} />
            <Route path="/offers" component={OffersPageComponent} />
            <Route path="/contactus" component={ContactUsComponent} />
            <Route path="/demandsupply" component={DemandAndSupplyComponent} />
            <Route path="/chat" component={ChatComponent} />
            <Route path="/demo" component={DemoPageComponent} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={SignUp} />
            <Route path="/profile" component={profile} />
          </Switch>
          <ToastContainer/>
        </header>
      </div>
    </Router>
  );
}



export default App;
